export enum StatusAgendamentoEnum {
  ABERTO = "ABERTO",
  AGUARDANDO_PAGAMENTO = "AGUARDANDO_PAGAMENTO",
  PAGO = "PAGO",
  AGENDADO = "AGENDADO",
  INICIADO = "INICIADO",
  FINALIZADO = "FINALIZADO",
  CANCELADO = "CANCELADO",
  REEMBOLSADO = "REEMBOLSADO",
}
