export enum ConselhoEnum {
  CRESS = "CRESS",
  COREN = "COREN",
  CRF = "CRF",
  CREFONO = "CREFONO",
  CREFITO = "CREFITO",
  CRM = "CRM",
  CRN = "CRN",
  CRO = "CRO",
  CRP = "CRP",
  CRBio = "CRBio",
  CRBM = "CRBM",
  CREF = "CREF",
  CRMV = "CRMV",
  CRTR = "CRTR",
}
