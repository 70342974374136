export enum RolesEnum {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_GERENTE = "ROLE_GERENTE",
  ROLE_COLABORADOR = "ROLE_COLABORADOR",
  ROLE_CLIENTE = "ROLE_CLIENTE",
  ROLE_SUPORTE = "ROLE_SUPORTE",
  PRESTADOR = "PRESTADOR",
  ATENDENTE_PRESTADOR = "ATENDENTE_PRESTADOR",
  PROFISSIONAL = "PROFISSIONAL",
}
